import React, { useState, useContext , useEffect} from 'react';
import { Tooltip } from 'antd';
import { appContext } from '../hooks/provider.tsx';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as SpenLogo } from './Spen.svg';
import { ReactComponent as MicrosoftLogo }  from "./icons8-microsoft.svg"
import axios from 'axios'
import { Dropdown, MenuProps, message } from "antd";
import { IStatus} from "./types.tsx";
import { ReactComponent as FactsetLogo } from "./FactSet_wordmark.svg"; // Import FactsetLogo


const Signup = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { setUser } = useContext(appContext);
  const navigate = useNavigate();
  const [error, setError] = React.useState<IStatus | null>({
    status: true,
    message: "All good",
});
const [loading, setLoading] = React.useState(false);
const SignupUrl = ' https://dst9sv62pk.execute-api.us-east-1.amazonaws.com/signup'

const [isMobile, setIsMobile] = useState(false); // Added state for mobile detection

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Set to true if screen width is less than 768px
    };

    handleResize(); // Check on initial render
    window.addEventListener('resize', handleResize); // Add event listener

    return () => {
      window.removeEventListener('resize', handleResize); // Clean up event listener
    };
  }, []);

  const handleSignup = async (email: string, password: string) => {
    if (isMobile) return;
    
    setError(null);
    try {
      const response = await axios.post(SignupUrl, {
        admin_email: email,  // Adjusted to match expected structure
        password: password   // Adjusted to match expected structure
      });
      const data = response.data
      console.log(data.data)

      if (data.status) {
        console.log('signup1:', data.status)
        console.log('signup2:', data.data)
        message.success(data.message);
        navigate('/security-code'); // Navigate to SecurityCode after signup
      } else {
        setError({ status: false, message: data.message });
        message.error(data.message);
      }
  } catch (err) {
    setError(err);
    message.error(err.message);
} finally {
    setLoading(false);
}
  };

  return (
    <div className="min-h-screen flex flex-col justify-between bg-gray-50">
      {isMobile ? ( // Display message if on mobile
        <div className="flex-grow flex items-center justify-center">
          <div className="bg-white p-8 rounded-lg shadow-md w-96 text-center">
            The app cannot be used in mobile display, please switch to desktop or laptop.
          </div>
        </div>
      ) : (
        <div className="flex-grow flex items-center justify-center">
          <div className="bg-white p-8 rounded-lg shadow-md w-96">
            <div className="flex items-center justify-center mb-6"> {/* Modified to flex container */}
              <SpenLogo className="w-8 h-8 mr-2" /> {/* Added SVG logo */}
              <div className="text-2xl font-bold text-center">Millennial Labs</div>
            </div>
            
            <Tooltip title="Not Available"> {/* Add Tooltip */}
              <button className="w-full mb-3 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 flex items-center justify-center">
                <MicrosoftLogo className="w-5 h-5 mr-2" /> {/* Use MicrosoftLogo component */}
                Signup with Microsoft
              </button>
            </Tooltip>
            
            <Tooltip title="Not Available"> {/* Add Tooltip */}
              <button className="w-full mb-6 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 flex items-center justify-center">
                <FactsetLogo className="w-5 h-5 mr-2" /> {/* Use FactsetLogo component */}
                Signup with Factset
              </button>
            </Tooltip>
            
            <div className="relative mb-6">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-300"></div>
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="px-2 bg-white text-gray-500">OR</span>
              </div>
            </div>
            
            <input
              type="email"
              placeholder="Work Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full mb-3 px-3 py-2 border border-gray-300 rounded-md"
            />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full mb-6 px-3 py-2 border border-gray-300 rounded-md"
            />
            
            <button 
              onClick={() => {handleSignup(email, password)}}
              className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-500 hover:bg-blue-600"
            >
              Signup
            </button>
            
            <div className="mt-4 text-center text-sm">
              <a href="/login" className="text-blue-500 hover:underline">Login</a> {/* Link to login page */}
            </div>
          </div>
        </div>
      )}
      <footer className="mt-8 text-center text-sm text-gray-500">
        <p>© Millennial Labs 2024</p>
        <div>
          <a href="#" className="hover:underline">Privacy Policy</a>
          {' • '}
          <a href="#" className="hover:underline">Terms of Service</a>
        </div>
      </footer>
    </div>
  );
};

export default Signup;