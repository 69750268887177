import React, { useState, useEffect } from "react";
import {
  eraseCookie,
  getLocalStorage,
  setLocalStorage,
  setCookie,
  getCookie,
} from "../components/utils.tsx";
import { message } from "antd";
import axios from 'axios'

export interface IUser {
  user_id: string;
  organization_id: string;
  name: string;
  email: string;
  password: string;
  is_admin: boolean;
}

export interface AppContextType {
  user: IUser | null;
  setUser: React.Dispatch<React.SetStateAction<IUser | null>>; // Update this type
  login: (userData: IUser) => void;
  logout: () => void;
  cookie_name: string;
  darkMode: string;
  setDarkMode: (mode: string) => void;
}

const cookie_name = "coral_app_cookie_";

export const appContext = React.createContext<AppContextType>({} as AppContextType);

const Provider = ({ children }: { children: React.ReactNode }) => {
  const storedValue = getLocalStorage("darkmode", false);
  const [darkMode, setDarkMode] = useState(
    storedValue === null ? "light" : storedValue === "dark" ? "dark" : "light"
  );
  const loginUrl = ' https://5bej6il62i.execute-api.us-east-1.amazonaws.com/login'

  const [user, setUser] = useState<IUser | null>(null);

  useEffect(() => {
    const storedUser = getLocalStorage("user", false);
    if (storedUser) {
      checkUserValidity(storedUser);
    } else {
      const cookieUser = getCookie(cookie_name); 
      if (cookieUser) {
        setUser(JSON.parse(cookieUser)); 
      }
    }
  }, []);


  const checkUserValidity = async (storedUser: IUser) => {
    try {
     
      const response = await axios.post(loginUrl, {email: storedUser.email , password: storedUser.password});
      
      if (response.data.status) {
        setUser(storedUser);
      } else {
        logout();
      }
    } catch (error) {
      console.error("Error validating user:", error);
      logout();
    }
  };

  const login = (userData: IUser) => {
    setUser(userData);
    setLocalStorage("user", JSON.stringify(userData), false);
    setCookie(cookie_name, JSON.stringify(userData), 7); 
  };

  const logout = () => {
    setUser(null);
    eraseCookie(cookie_name);
    localStorage.removeItem("user");
    message.info("Logged out successfully");
  };

  const updateDarkMode = (mode: string) => {
    setDarkMode(mode);
    setLocalStorage("darkmode", mode, false);
  };

  return (
    <appContext.Provider
      value={{
        user,
        setUser,
        login,
        logout,
        cookie_name,
        darkMode,
        setDarkMode: updateDarkMode,
      }}
    >
      {children}
    </appContext.Provider>
  );
};

export default Provider;