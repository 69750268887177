import {
    IStatus,
  } from "./types.tsx";


  export function setCookie(name: string, value: any, days: number) {
    let expires = "";
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }

  export function getCookie(name: string): string | null {
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  export function setLocalStorage(
    name: string,
    value: any,
    stringify: boolean = true
  ) {
    if (stringify) {
      localStorage.setItem(name, JSON.stringify(value));
    } else {
      localStorage.setItem(name, value);
    }
  }
  
  
  export function getLocalStorage(name: string, stringify: boolean = true): any {
    if (typeof window !== "undefined") {
      const value = localStorage.getItem(name);
      try {
        if (stringify) {
          return JSON.parse(value!);
        } else {
          return value;
        }
      } catch (e) {
        return null;
      }
    } else {
      return null;
    }
  }
export async function fetchJSON(
    url: string | URL,
    payload: any = {},
    onSuccess: (data: any) => void,
    onError: (error: IStatus) => void
  ) {
    return fetch(url, payload)
      .then(function (response) {
        if (response.status !== 200) {
          console.log(
            "Looks like there was a problem. Status Code: " + response.status,
            response
          );
          response.json().then(function (data) {
            console.log("Error data", data);
          });
          onError({
            status: false,
            message:
              "Connection error " + response.status + " " + response.statusText,
          });
          return;
        }
        return response.json().then(function (data) {
          onSuccess(data);
        });
      })
      .catch(function (err) {
        console.log("Fetch Error :-S", err);
        onError({
            status: false,
            message: `There was an error connecting to server. (${err}) `,
          });
        });
    }
export const capitalize = (s: string) => {
        if (typeof s !== "string") return "";
        return s.charAt(0).toUpperCase() + s.slice(1);
      };
      
export function eraseCookie(name: string) {
        document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
      }
export function truncateText(text: string, length = 50) {
  if (text.length > length) {
    return text.substring(0, length) + " ...";
  }
  return text;
}
export const timeAgo = (dateString: string): string => {
    // Parse the date string into a Date object
    const timestamp = new Date(dateString);
  
    // Check for invalid date
    if (isNaN(timestamp.getTime())) {
      return "Invalid date"; 
    }
  
    // Get the current time
    const now = new Date();
  
    // Calculate the difference in milliseconds
    const timeDifference = now.getTime() - timestamp.getTime();
  
    // Convert time difference to minutes and hours
    const minutesAgo = Math.floor(timeDifference / (1000 * 60));
    const hoursAgo = Math.floor(minutesAgo / 60);
  
    // Format the date into a readable format e.g. "November 27"
    const options: Intl.DateTimeFormatOptions = { month: "long", day: "numeric" };
    const formattedDate = timestamp.toLocaleDateString(undefined, options);
  
    // Determine the time difference string
    let timeAgoStr: string;
    if (minutesAgo < 1) {
      timeAgoStr = "just now";
    } else if (minutesAgo < 60) {
      // Less than an hour ago, display minutes
      timeAgoStr = `${minutesAgo} ${minutesAgo === 1 ? "minute" : "minutes"} ago`;
    } else if (hoursAgo < 24) { // Less than a day ago, display hours
        timeAgoStr = `${hoursAgo} ${hoursAgo === 1 ? "hour" : "hours"} ago`;
      } else {
        // More than a day ago, display the formatted date
        timeAgoStr = formattedDate;
      }
    
      // Return the final readable string
      return timeAgoStr;
    };
    export const formatDuration = (seconds: number) => {
      const units = [
        { label: " day", seconds: 86400 },
        { label: " hr", seconds: 3600 },
        { label: " min", seconds: 60 },
        { label: " sec", seconds: 1 },
      ];
    
      let remainingSeconds = seconds;
      const parts: string[] = [];
    
      for (const { label, seconds: unitSeconds } of units) {
        const count = Math.floor(remainingSeconds / unitSeconds);
        if (count > 0) {
          parts.push(count + (count > 1 ? label + "s" : label));
          remainingSeconds -= count * unitSeconds;
        }
      }
    
      return parts.length > 0 ? parts.join(" ") : "0 sec";
    };

    export const examplePrompts = [
      {
        title: "Stock Price",
        prompt:
          "Plot a chart of NVDA and TESLA stock price for 2023. Save the result to a file named nvda_tesla.png",
      },
      {
        title: "Sine Wave",
        prompt:
          "Write a python script to plot a sine wave and save it to disc as a png file sine_wave.png",
      },
      {
        title: "Markdown",
        prompt:
          "List out the top 5 rivers in africa and their length and return that as a markdown table. Do not try to write any code, just write the table",
      },
      {
        title: "Paint",
        prompt:
          "paint a picture of a glass of ethiopian coffee, freshly brewed in a tall glass cup, on a table right in front of a lush green forest scenery",
      },
    ];
    