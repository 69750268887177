import * as React from "react";

const LoadingIndicator: React.FC = () => {
    return (
        <div className="flex justify-center items-center h-12">
            <div className="w-3 h-3 bg-blue-500 rounded-full animate-blink mr-2"></div>
            <span className="text-gray-500">Byrde is thinking...</span>
        </div>
    );
};

export default LoadingIndicator;