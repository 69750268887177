import React, { useState, useEffect } from 'react';
import { useConfigStore } from "../../../hooks/store.tsx";

const PlansView = () => {
    const plans = useConfigStore((state) => state.plans);
    const [typingIndex, setTypingIndex] = useState(-1);

   
    useEffect(() => {
        if (plans.length > 0) {
            const lastPlan = plans[plans.length - 1];
            const totalItems = lastPlan.init_plan.length + lastPlan.plan.length + lastPlan.current_plan_step.length;
            
            if (typingIndex < totalItems - 1) {
                const timer = setTimeout(() => {
                    setTypingIndex(prev => prev + 1);
                }, 50); 
                return () => clearTimeout(timer);
            }
        }
    }, [plans, typingIndex]);

    return (
        <div className="flex flex-col h-full">
            <div className="p-4 border-b">
                <h2 className="text-xl font-semibold">Plans</h2>
            </div>
            <div className="flex-grow overflow-auto p-4">
                {plans.map((plan, index) => (
                    <div key={index} className="mb-6">
                        <h3 className="text-lg font-semibold mb-2">Plan {index + 1}</h3>
                        <div className="space-y-4">
                            <div>
                                <h4 className="font-semibold">Initial Plan:</h4>
                                <ul className="list-disc list-inside">
                                    {plan.init_plan.map((item, idx) => (
                                        <li key={idx} className="typing">{item}</li>
                                    ))}
                                </ul>
                            </div>
                            <div>
                                <h4 className="font-semibold">Plan:</h4>
                                <ul className="list-disc list-inside">
                                    {plan.plan.map((item, idx) => (
                                        <li key={idx} className="typing">{item}</li>
                                    ))}
                                </ul>
                            </div>
                            <div>
                                <h4 className="font-semibold">Current Step Plan:</h4>
                                <ul className="list-disc list-inside">
                                    {plan.current_plan_step.map((item, idx) => (
                                        <li key={idx} className="typing">{item}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default PlansView;