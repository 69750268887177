import React, { useContext } from 'react';
import { appContext } from "../hooks/provider.tsx";
import {
  BookOpenIcon,
  SparklesIcon,
} from "@heroicons/react/24/outline";

const Dashboard = () => {
  const { user } = useContext(appContext);
  const getGreeting = () => {
    const currentHour = new Date().getHours();
    if (currentHour < 12) return "Good morning";
    if (currentHour < 18) return "Good afternoon";
    return "Good evening";
  };

  const recentlyVisited = [
    { title: 'Overview', date: '2w ago' },
    { title: '(Reporting) (PRD)', date: '1d ago' },
    { title: '(Modelling ) (PRD)', date: '2w ago' },
    { title: 'View of Projects, Tasks & Sprints', date: 'Aug 6, 2023' },
    { title: 'Byrde (PRD)', date: 'Jul 30' },
    { title: 'Job Requirements', date: '1d ago' },
  ];

  const learnItems = [
    { title: 'The ultimate guide to Notion templates', duration: '5m read' },
    { title: 'Customize & style your content', duration: '6m read' },
    { title: 'Getting started with projects and tasks', duration: '7m read' },
    { title: 'Using Notion AI to increase your impact', duration: '8m read' },
  ];

  const featuredTemplates = [
    { title: 'Life Wiki', author: 'Brian DeChesare' },
    { title: 'Journal', author: 'Brian DeChesare' },
    { title: 'To-do List', author: 'Brian DeChesare' },
    { title: 'Simple Budget', author: 'Brian DeChesare' },
  ];

  return (
    <div className="bg-gray-100 p-8 font-sans h-full overflow-y-auto">
      <h2 className="text-2xl font-semibold mb-8">{getGreeting()}, {user?.name}</h2> {/* Updated greeting */}
      
      <div className="mb-10">
        <h3 className="text-sm text-gray-600 mb-4">Recently visited</h3>
        <div className="grid grid-cols-3 gap-4">
          {recentlyVisited.map((item, index) => (
            <div key={index} className="bg-white p-4 rounded-lg shadow-sm">
              <div className="text-gray-800 mb-2">{item.title}</div>
              <div className="text-xs text-gray-500">{item.date}</div>
            </div>
          ))}
        </div>
      </div>

      <div className="mb-10">
        <h3 className="text-lg font-semibold mb-4 flex items-center">
          <BookOpenIcon className="w-5 h-5 mr-2" />
          Learn
        </h3>
        <div className="grid grid-cols-3 gap-6">
          {learnItems.map((item, index) => (
            <div key={index} className="bg-white rounded-lg overflow-hidden shadow-sm">
              <div className="h-32 bg-gray-200"></div>
              <div className="p-4">
                <h4 className="font-semibold mb-2">{item.title}</h4>
                <p className="text-sm text-gray-600">{item.duration}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div>
        <h3 className="text-lg font-semibold mb-4 flex items-center">
          <SparklesIcon className="w-5 h-5 mr-2" />
          Featured Financial Model templates
        </h3>
        <div className="grid grid-cols-3 gap-6">
          {featuredTemplates.map((item, index) => (
            <div key={index} className="bg-white rounded-lg overflow-hidden shadow-sm">
              <div className="h-32 bg-gray-200"></div>
              <div className="p-4">
                <h4 className="font-semibold mb-1">{item.title}</h4>
                <p className="text-sm text-gray-600">By {item.author}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;