import React from 'react';

const ByrdePrivacyPolicy = () => {
  return (
    <div className="min-h-screen bg-gray-900 text-gray-300">
      <header className="bg-gray-800 shadow-md">
        <div className="container mx-auto px-4 py-4 flex justify-between items-center">
          <h1 className="text-2xl font-bold text-white">Millennial Labs</h1>
          <button className="text-white">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>
        </div>
      </header>

      <main className="container mx-auto px-4 py-8">
        <h1 className="text-5xl font-bold text-white mb-2">Byrde</h1>
        <h2 className="text-4xl font-bold text-gray-400 mb-8">Privacy Policy</h2>

        <p className="text-sm mb-4">LAST UPDATED AUGUST 5, 2024</p>

        <p className="mb-4">
          If your organization signed a superseding agreement with Millennial Labs, that
          Agreement may have modified the privacy policy below. Please contact your
          organization's Admin for details.
        </p>

        <p className="mb-8">
          Thanks for using Byrde! Here we describe how we collect, use, and handle your
          personal data when you use our websites, software, and services ("Services").
        </p>

        <section className="mb-8">
          <h3 className="text-2xl font-bold text-white mb-4">What & Why</h3>
          <p className="mb-4">
            We collect and use the following information to provide, improve, protect, and
            promote our Services.
          </p>
          <ul className="list-disc list-inside space-y-2">
            <li>Account information. We collect, and associate with your account, the
              information you provide to us when you do things such as sign up for your
              account, upgrade to a paid plan, and set up two-factor authentication (like
              your name, email address, phone number, payment info, and physical address).</li>
            <li>Third Party Integration Data. Our Services are designed as a simple and
              personalized way for you to integrate with third party tools. To make that
              possible, we store, process, and transmit data and information related to these
              third-party tools.</li>
            <li>Usage information. We collect information related to how you use the Services,
              including actions you take in your account (like creating new chats, using third
              party integrations, and creating automations).</li>
            <li>Device information. We also collect information from and about the devices you
              use to access the Services.</li>
            <li>First-party cookies and other technologies. We use technologies like first-party
              cookies and caching to provide, improve, protect, and promote our Services.</li>
            <li>Marketing. We give users the option to use some of our Services free of
              charge. These free Services are made possible by the fact that some users
              upgrade to one of our paid Services.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h3 className="text-2xl font-bold text-white mb-4">With Whom</h3>
          <p className="mb-4">
            We may share information as discussed below, but we won't sell it to advertisers or
            other third parties.
          </p>
          <ul className="list-disc list-inside space-y-2">
            <li>Others working for and with Millennial Labs.</li>
            <li>Other users.</li>
            <li>Team Admins.</li>
            <li>Law & Order and the Public Interest.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h3 className="text-2xl font-bold text-white mb-4">How</h3>
          <p className="mb-4">
            Security. We have a team dedicated to keeping your information secure and testing
            for vulnerabilities.
          </p>
          <p className="mb-4">
            User Controls. You can access, amend, download, and delete your personal
            information by modifying your profile in settings.
          </p>
          <p className="mb-4">
            Retention. When you sign up for an account with us, we'll retain information you
            store on our Services for as long as your account exists or as long as we need it to
            provide you the Services.
          </p>
        </section>

        <section className="mb-8">
          <h3 className="text-2xl font-bold text-white mb-4">Changes</h3>
          <p className="mb-4">
            If we're involved in a reorganization, merger, acquisition, or sale of our assets, your
            data may be transferred as part of that deal. We'll notify you (for example, via a
            message to the email address associated with your account) of any such deal and
            outline your choices in that event.
          </p>
          <p className="mb-4">
            We may revise this Privacy Policy from time to time, and will post the most current
            version on our website. If a revision meaningfully reduces your rights, we will notify
            you.
          </p>
        </section>
      </main>

      <footer className="bg-gray-800 text-white py-4">
        <div className="container mx-auto px-4 text-center">
          <p>&copy; 2024 Millennial Labs. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default ByrdePrivacyPolicy;