import { create } from "zustand";
import { v4 as uuidv4 } from "uuid";
import { IChatMessage, IChatSession, IPlans, IUser } from "../components/types";

interface ConfigState {
 
  messages: IChatMessage[];
  setMessages: (messages: IChatMessage[] | ((prevMessages: IChatMessage[]) => IChatMessage[])) => void;
  session: IChatSession | null;
  setSession: (session: IChatSession | null) => void;
  sessions: IChatSession[];
  setSessions: (sessions: IChatSession[] | ((prevSessions: IChatSession[]) => IChatSession[])) => void;
  version: string | null;
  setVersion: (version: string) => void;
  plans: IPlans[];
  setPlans: (plans: IPlans[] | ((prevPlans: IPlans[]) => IPlans[])) => void;
  connectionId: string;
  setConnectionId: (connectionId: string) => void;
}

export const useConfigStore = create<ConfigState>()((set) => ({
  messages: [],
  setMessages: (messages) => set((state) => ({
    messages: typeof messages === 'function' ? messages(state.messages) : messages
  })),
  session: null,
  setSession: (session) => set({ session }),
  sessions: [],
  setSessions: (sessions) => set((state) => ({
    sessions: typeof sessions === 'function' ? sessions(state.sessions) : sessions
  })),
  version: null,
  setVersion: (version) => set({ version }),
  connectionId: uuidv4(),
  setConnectionId: (connectionId) => set({ connectionId }),
  plans: [],
  setPlans: (plans) => set((state) => ({
    plans: typeof plans === 'function' ? plans(state.plans) : plans
  })),
  
}));