import * as React from "react";
import { PlusIcon, AdjustmentsVerticalIcon } from "@heroicons/react/24/outline";

const ReportBuilder: React.FC<{ onClose: () => void }> = ({ onClose }) => {
    const [title, setTitle] = React.useState("Untitled");
    const [content, setContent] = React.useState("");
    const [showDropdown, setShowDropdown] = React.useState(false);

    const handleTitleChange = (e: React.ChangeEvent<HTMLDivElement>) => {
        setTitle(e.target.innerText);
    };

    const handleContentChange = (e: React.ChangeEvent<HTMLDivElement>) => {
        setContent(e.target.innerText);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (!(event.target as HTMLElement).closest(".dropdown") && !(event.target as HTMLElement).closest(".dropdown-toggle")) {
            setShowDropdown(false);
        }
    };

    React.useEffect(() => {
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    return (
        <div className="flex flex-col items-start justify-start w-full h-full bg-white p-8">
            <button 
                onClick={onClose} 
                className="text-gray-500 hover:text-gray-700 mb-4 self-end"
            >
                Close
            </button>
            <div
                className="text-3xl font-bold mb-4 outline-none w-full text-left" 
                contentEditable
                suppressContentEditableWarning
                onInput={handleTitleChange}
            >
                {title}
            </div>
            
            <div className="relative group w-full"> {/* Added relative and group classes */}
                <textarea
                    className="text-gray-400 outline-none w-full text-left" 
                    placeholder="Write something, or press 'space' for AI, '/' for commands..." 
                    value={content}
                    onChange={(e) => setContent(e.target.value)} 
                    style={{ color: content ? 'black' : 'inherit' }} 
                />
                <div className="absolute left-0 top-1/2 transform -translate-y-1/2 -translate-x-6 opacity-0 group-hover:opacity-100 transition-opacity flex space-x-2 dropdown-toggle"> {/* Adjusted positioning */}
                    <button onClick={() => setShowDropdown(!showDropdown)}>
                        <PlusIcon className="h-5 w-5 text-gray-500" /> {/* Plus icon */}
                    </button>
                    <AdjustmentsVerticalIcon className="h-5 w-5 text-gray-500" /> {/* AdjustmentsVertical icon */}
                </div>
                {showDropdown && (
                    <div className="dropdown absolute left-8 top-12 bg-white border border-gray-300 shadow-lg rounded-md p-4 w-64 max-h-64 overflow-y-auto"> {/* Dropdown container */}
                        <div className="flex flex-col space-y-2">
                            <div className="flex items-center space-x-2">
                                <span className="text-gray-700">Text</span>
                                <span className="text-gray-500 text-sm">Just start writing with plain text.</span>
                            </div>
                            <div className="flex items-center space-x-2">
                                <span className="text-gray-700">Page</span>
                                <span className="text-gray-500 text-sm">Embed a sub-page inside this page.</span>
                            </div>
                            <div className="flex items-center space-x-2">
                                <span className="text-gray-700">To-do list</span>
                                <span className="text-gray-500 text-sm">Track tasks with a to-do list.</span>
                            </div>
                            <div className="flex items-center space-x-2">
                                <span className="text-gray-700">Heading 1</span>
                                <span className="text-gray-500 text-sm">Big section heading.</span>
                            </div>
                            <div className="flex items-center space-x-2">
                                <span className="text-gray-700">Heading 2</span>
                                <span className="text-gray-500 text-sm">Medium section heading.</span>
                            </div>
                            {/* Add more items to test scrollability */}
                            <div className="flex items-center space-x-2">
                                <span className="text-gray-700">Item 6</span>
                                <span className="text-gray-500 text-sm">Description for item 6.</span>
                            </div>
                            <div className="flex items-center space-x-2">
                                <span className="text-gray-700">Item 7</span>
                                <span className="text-gray-500 text-sm">Description for item 7.</span>
                            </div>
                            <div className="flex items-center space-x-2">
                                <span className="text-gray-700">Item 8</span>
                                <span className="text-gray-500 text-sm">Description for item 8.</span>
                            </div>
                            <div className="flex items-center space-x-2">
                                <span className="text-gray-700">Item 9</span>
                                <span className="text-gray-500 text-sm">Description for item 9.</span>
                            </div>
                            <div className="flex items-center space-x-2">
                                <span className="text-gray-700">Item 10</span>
                                <span className="text-gray-500 text-sm">Description for item 10.</span>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ReportBuilder;