import './App.css';
import RAView from './components/views/spreadsheet/ra.tsx';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'; 
import ReportBuilder from './components/views/spreadsheet/reportbuilder.tsx';
import Login from './components/login.tsx';
import { useContext, useEffect } from 'react';
import { appContext } from './hooks/provider.tsx';
import Signup from './components/signup.tsx';
import SecurityCode from './components/securitycode.tsx';
import Provider from './hooks/provider.tsx';
import ByrdePrivacyPolicy from './components/privacypolicy.tsx';
import Dashboard from './components/dashboard.tsx';


function App() {
  const { user, setUser } = useContext(appContext);
  console.log("current user is 4:", user)

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
      console.log('storeduser:' , storedUser)
    }
  }, [setUser]);

  return (
   
      <Router>
      <div className="App">
        <Routes>
        <Route path="/" element={user ? <Navigate to="/ra" /> : <Signup />} /> {/* Redirect to /ra if user is logged in */}
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/security-code" element={<SecurityCode />} /> 
          <Route path="/dashboard" element={<Dashboard />} /> 
          <Route path="/privacypolicy" element={<ByrdePrivacyPolicy/>}/>
          <Route path="/ra" element={user ? <RAView /> : <Navigate to="/" />} /> 
          <Route path="/reportbuilder" element={<ReportBuilder />} /> 
        </Routes>
      </div>
    </Router>
    
    
  );
}

export default App;