import Chat from "./spread-side.tsx";
import axios from "axios";
import SessionsView from "./sessions.tsx";
import { IChatSession, IChatMessage, IStatus } from "../../types.tsx";
import * as React from "react";
import { message } from "antd";
import { fetchJSON } from "../../utils.tsx";
import { useConfigStore } from "../../../hooks/store.tsx";
import Sidebar from "./Sideview.tsx";
import PlansView from "./planning.tsx";
import ReportBuilder from "./reportbuilder.tsx";
import  OnboardingModal  from "../../onboarding.tsx";
import { appContext } from "../../../hooks/provider.tsx";
import LogoutButton from "../../logout.tsx"; 
import Dashboard from "../../dashboard.tsx";
import Header from "../../header.tsx";

const RAView = () => {
  const session = useConfigStore((state) => state.session);
  const plans = useConfigStore((state) => state.plans);
  const [showReportBuilder, setShowReportBuilder] = React.useState(false);

  const [loading, setLoading] = React.useState(false);
  const [messages, setMessages] = React.useState<IChatMessage[] | null>(null);
  const [error, setError] = React.useState<IStatus | null>({
    status: true,
    message: "All good",
  });
  const { user } = React.useContext(appContext);
  const [onboardingStep, setOnboardingStep] = React.useState(0);

  const onboardingSteps = [
    {
      title: "Start Building Models",
      content: "Type your query here to start building financial models.",
      position: { top: 'calc(100% - 100px)', left: '50%' }
    },
    {
      title: "Financial Models",
      content: "Access your financial models here.",
      position: { top: 'calc(100% - 200px)', left: '20px' }
    },
    {
      title: "Analyst Reports",
      content: "Generate and view analyst reports.",
      position: { top: 'calc(100% - 150px)', left: '20px' }
    },
    {
      title: "Create New Session",
      content: "Start a new session by clicking this button.",
      position: { top: '20px', left: '20px' }
    }
  ];

  const handleNextStep = () => {
    if (onboardingStep < onboardingSteps.length - 1) {
      setOnboardingStep(onboardingStep + 1);
    } else {
      setOnboardingStep(0);
    }
  };

  const handleSkipTour = () => {
    setOnboardingStep(0);
  };

  const startOnboarding = () => {
    setOnboardingStep(1);
  };


  
  return (
    <div className="flex flex-col h-screen bg-gray-100"> 
    <div className="flex flex-grow overflow-hidden">
      <Sidebar className="w-1/4" loading={loading} setLoading={setLoading} onReportBuilderClick={() => setShowReportBuilder(true)} startOnboarding={startOnboarding}/>
      <div className="flex-grow flex flex-col">
      <Header />
      <div className="flex-grow p-4 overflow-hidden"> 
      {showReportBuilder ? (
     <div className="w-full h-full"> 
     <ReportBuilder onClose={() => setShowReportBuilder(false)} />
 </div>
  ) : (
    <>
        {!session && (
          <div className="w-full h-full flex items-center justify-center">
          <div className="w-full h-full overflow-y-auto mx-auto" id="middle">
            
            <Dashboard />
          </div>
        </div>
        )}

        {session !== null && (
          <div className="flex h-full space-x-4"> 
          <div className={`${plans.length > 0 ? 'w-1/2' : 'w-full'} bg-white rounded-lg shadow-lg overflow-hidden`}> 
            <Chat />
          </div>
          {plans.length > 0 && (
            <div className="w-1/2 bg-white rounded-lg shadow-lg overflow-hidden">
              <PlansView />
              </div>
            )}
          </div>
        )}
    </>
  )}
      </div>
      </div>
      </div>
    </div>
  );
};

export default RAView;
