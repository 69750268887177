import React, { useState, useContext , useEffect} from 'react';
import { appContext } from "../../../hooks/provider.tsx";
import { v4 as uuidv4 } from "uuid";
import axios from 'axios';
import {
    ArrowLeftCircleIcon,
    DocumentDuplicateIcon,
    ArrowPathIcon,
    PaperClipIcon
} from "@heroicons/react/24/outline";
import { message as ToastMessage, Dropdown, MenuProps } from "antd";
import { IChatMessage, IChatSession, IStatus, IPlans } from "../../types.tsx";
import { MarkdownView } from "../../atoms.tsx";
import { useConfigStore } from "../../../hooks/store.tsx";
import LoadingIndicator from "../../loading.tsx";

function parseExecutionResult(data: string): Record<string, string | number> {
    const result: Record<string, string | number> = {};
   
    const cleanedData = data.replace(/^"?execution_result:\\n/, '').replace(/"$/, '');
    
   
    const lines = cleanedData.split('\\n');
    console.log('lines:', lines)
    
    lines.forEach(line => {
        const val = line.match(/date\\",\s*'([\d-]+)'/);
        console.log("val:", val)
        console.log('val1:', val[1])
        const dynamicPattern = new RegExp(`Index: (.*?), ${val[1]} (.*?)(?:,|$)`); 
        const match = line.match(dynamicPattern); 
       
        if (match) {
            let [, key, value] = match;
            // Clean up the key and value
            key = key.trim();
            console.log("key:", key)
            console.log('value', value)
            
            // Extract the actual value after the date
            const dataParts = value.split('Data: ');
            console.log('dataparts', dataParts)
            if (dataParts.length > 1) {
                value = dataParts[1].trim();
            } else {
                value = value.trim();
            }
            
            
            const numValue = Number(value);
            result[key] = isNaN(numValue) ? value : numValue;
        }
    });
    
    return result;
}


const simulateTyping = (text: string, setMessages: (messages: IChatMessage[] | ((prevMessages: IChatMessage[]) => IChatMessage[])) => void, delay: number = 50) => {
    let index = 0;
    const messageId = uuidv4();
    setMessages((messages) => [...messages, { text: "", sender: "bot", msg_id: messageId }]);
    
    const interval = setInterval(() => {
        if (index < text.length) {
            setMessages((messages) => 
                messages.map(msg => 
                    msg.msg_id === messageId 
                        ? { ...msg, text: msg.text + text.charAt(index) } 
                        : msg
                )
            );
            index++;
        } else {
            clearInterval(interval);
        }
    }, delay);
};
const simulatePlanTyping = (plan: IPlans, setPlans: (plans: IPlans[] | ((prevPlans: IPlans[]) => IPlans[])) => void, delay: number = 50) => {
    let index = 0;
    const planId = uuidv4();
    const cleanedPlan = {
        ...plan,
        init_plan: plan.init_plan.map(item => item.replace(/^\d+\.\s*/, '').trim()),
        plan: plan.plan.map(item => item.replace(/^\d+\.\s*/, '').trim()),
        current_plan_step: plan.current_plan_step.map(item => item.replace(/^\d+\.\s*/, '').trim())
    };

    setPlans((prevPlans) => [...prevPlans, { ...cleanedPlan, session_id: planId, init_plan: [], plan: [], current_plan_step: [] }]);

    const typeNext = () => {
        if (index < plan.init_plan.length) {
            setPlans((prevPlans) => 
                prevPlans.map(p => 
                    p.session_id === planId 
                        ? { ...p, init_plan: [...p.init_plan, plan.init_plan[index]] } 
                        : p
                )
            );
            index++;
            setTimeout(typeNext, delay);
        } else if (index < plan.init_plan.length + plan.plan.length) {
            const planIndex = index - plan.init_plan.length;
            setPlans((prevPlans) => 
                prevPlans.map(p => 
                    p.session_id === planId 
                        ? { ...p, plan: [...p.plan, plan.plan[planIndex]] } 
                        : p
                )
            );
            index++;
            setTimeout(typeNext, delay);
        } else if (index < plan.init_plan.length + plan.plan.length + plan.current_plan_step.length) {
            const stepIndex = index - plan.init_plan.length - plan.plan.length;
            setPlans((prevPlans) => 
                prevPlans.map(p => 
                    p.session_id === planId 
                        ? { ...p, current_plan_step: [...p.current_plan_step, plan.current_plan_step[stepIndex]] } 
                        : p
                )
            );
            index++;
            setTimeout(typeNext, delay);
        }
    };

    typeNext();
};

const Chat = (
    
) => {
    const session = useConfigStore((state) => state.session);
    const messages = useConfigStore((state) => state.messages);
    const setMessages = useConfigStore((state) => state.setMessages);
    const plans = useConfigStore((state) => state.plans);
    const setPlans = useConfigStore((state) => state.setPlans);
    const [input, setInput] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState<IStatus | null>({
        status: true,
        message: "All good",
    });
    const { user, setUser } = useContext(appContext);
    const [showDropdown, setShowDropdown] = React.useState(false);
    const messageEndRef = React.useRef<HTMLDivElement>(null);
    const [ws, setWs] = React.useState<WebSocket | null>(null);

    const scrollToBottom = () => {
        messageEndRef.current?.scrollIntoView({ behavior: "smooth" }); 
    };

    React.useEffect(() => {
        scrollToBottom(); 
    }, [messages, loading]);

   
    const isValidJson = (str: string): boolean => {
        try {
            JSON.parse(str);
            return true;
        } catch (e) {
            return false;
        }
    };
    

    const handleWebSocketMessage = (data: string) => {
        if (!isValidJson(data)) {
            console.error("Received invalid JSON:", data);
            return;
        }
        let inCodeInterpreterBlock = false; 
        let executionResult: Record<string, string | number> = {};
        let currentPlan: IPlans | null = null; 
    
        const parsedData = JSON.parse(data);
        console.log("parseddata:", parsedData);
    
        if (parsedData.data.trim()) { 
            try {
                const { event, data } = parsedData; 
                if (data.startsWith('"Planner"')) {
                    inCodeInterpreterBlock = false;
                    const newPlan: IPlans = {  // Changed from 'let currentPlan: IPlans | null'
                        session_id: session.id,
                        count: 1,
                        init_plan: [],
                        plan: [],
                        current_plan_step: []
                    };
                    setPlans(prevPlans => [...prevPlans, newPlan]);
                } else if (data.startsWith('"CodeInterpreter"')) {
                    inCodeInterpreterBlock = true;
                } else if (inCodeInterpreterBlock && data.startsWith('"execution_result:')) {
                    executionResult = parseExecutionResult(data);
                    console.log("Parsed execution result:", executionResult);
                } else if (data.startsWith('"init_plan:')) {
                    const initPlanItem = data.replace(/^"init_plan:\s*/, '').replace(/"$/, '');
                    setPlans(prevPlans => {
                        const updatedPlans = [...prevPlans];
                        const lastPlan = updatedPlans[updatedPlans.length - 1];
                        lastPlan.init_plan.push(initPlanItem);
                        return updatedPlans;
                    });
                } else if (data.startsWith('"plan:')) { 
                    const planItem = data.replace(/^"plan:\s*/, '').replace(/"$/, '');
                    setPlans(prevPlans => {
                        const updatedPlans = [...prevPlans];
                        const lastPlan = updatedPlans[updatedPlans.length - 1];
                        lastPlan.plan.push(planItem);
                        return updatedPlans;
                    });
                } else if (data.startsWith('"current_plan_step:')) {
                    const currentPlanStepItem = data.replace(/^"current_plan_step:\s*/, '').replace(/"$/, '');
                    setPlans(prevPlans => {
                        const updatedPlans = [...prevPlans];
                        const lastPlan = updatedPlans[updatedPlans.length - 1];
                        lastPlan.current_plan_step.push(currentPlanStepItem);
                        return updatedPlans;
                    });
                }
    
                if (!/^"(init_plan:|plan:|current_plan_step:|Planner|CodeInterpreter|Sending message to)/.test(data) && !inCodeInterpreterBlock) {
                    simulateTyping(data.trim(), setMessages);
                }   
            } catch (err) {
                console.error("Failed to process line:", err);
            }
        }
    
        if (parsedData.data.includes('Message processed')) { 
            if (ws) {
                ws.close(); 
                setWs(null); 
            }
        }
    };

    const handleSend = (sessionId: string, userId: string, query: string) => {
        setError(null);
        setLoading(true);
        setInput('');

        const socket = new WebSocket('wss://xcogmcyi6l.execute-api.us-east-1.amazonaws.com/production');

        socket.onopen = () => {
            console.log('WebSocket connection established');
            socket.send(JSON.stringify({ session_id: sessionId, user_id: userId, message: query }));
        };

        socket.onmessage = (event) => {
            if (event.data === 'Message Processed'){
                console.log('Closing WebSocket connection');
                socket.close();
            }
            handleWebSocketMessage(event.data);
            setLoading(false);
        };
      

        socket.onerror = (error) => {
            console.error('WebSocket error:', error);
            setError({ status: false, message: 'WebSocket error occurred' });
            setLoading(false);
        };

        socket.onclose = () => {
            console.log('WebSocket connection closed');
           
        };

        
       
    };

    const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const value = event.target.value;
        setInput(value);
        if (value.includes("model")) {
            setShowDropdown(true);
        } else {
            setShowDropdown(false); 
        }
    };
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
        if (event.key === "Enter" && !event.shiftKey) {
            event.preventDefault();
            if (!loading && input.trim() !== '' && user?.user_id) {
                if (session) {
                    setMessages((messages) => [...messages, { text: input, sender: "user", msg_id: uuidv4() }]);
                    handleSend(session.id, user.user_id, input);
                } else {
                    ToastMessage.error("Session is not available.");
                }
            }
        }
    };
    
    const messageListView = Array.isArray(messages) ? messages
    .filter((message: IChatMessage) => message.text.trim() !== "")
    .map((message: IChatMessage, i: number) => {
        const isUser = message.sender === "user";
        const alignmentClass = isUser ? "items-end" : "items-start";
        const bgColorClass = isUser ? "bg-blue-500" : "bg-gray-200";
        const textColorClass = isUser ? "text-white" : "text-gray-800";
        const cleanedText = message.text.replace(/^"|"$/g, '').replace(/\\n/g, ' ');
        
        return (
            <div className={`flex flex-col ${alignmentClass} mb-4`} key={"message" + i}>
                <div className={`w-3/4 rounded-lg p-3 ${bgColorClass} ${textColorClass}`}>
                    <div className="font-semibold mb-1">{isUser ? "You" : "Byrde"}</div>
                    <div className="prose dark:prose-invert">
                        <MarkdownView className="text-sm" data={cleanedText} showCode={false} />
                    </div>
                    <div className="flex mt-2 space-x-2">
                        <button className="text-xs opacity-50 hover:opacity-100">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                            </svg>
                        </button>
                        <button className="text-xs opacity-50 hover:opacity-100">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                <path d="M8 2a1 1 0 000 2h2a1 1 0 100-2H8z" />
                                <path d="M3 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v6h-4.586l1.293-1.293a1 1 0 00-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L10.414 13H15v3a2 2 0 01-2 2H5a2 2 0 01-2-2V5zM15 11h2a1 1 0 110 2h-2v-2z" />
                            </svg>
                        </button>
                        <button className="text-xs opacity-50 hover:opacity-100">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 1.414L10.586 9H7a1 1 0 100 2h3.586l-1.293 1.293a1 1 0 101.414 1.414l3-3a1 1 0 000-1.414z" clipRule="evenodd" />
                            </svg>
                        </button>
                        <button className="text-xs opacity-50 hover:opacity-100">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z" clipRule="evenodd" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        );
    }) : null;

    return (
        <div className="text-primary relative rounded-lg bg-white shadow-lg w-full max-w-5xl h-full flex flex-col">
            <div className="flex h-full w-full flex-col rounded-lg p-4">
                <div className="flex mt-1 mb-1"></div>
                <div className="p-2 bg-gray-100 rounded-lg flex-1 overflow-auto w-full h-96">
                    <div className="w-full min-h-full">
                        <div className="ml-2">{messageListView}</div>
                        <div ref={messageEndRef} />
                    </div>
                </div>
                {loading && <LoadingIndicator />}
                <form
                    autoComplete="on"
                    className="relative mt-4 w-full"
                    onSubmit={(e) => {
                        e.preventDefault();
                        if (session && input.trim() !== '' && user?.user_id) {
                            setMessages((messages) => [...messages, { text: input, sender: "user", msg_id: uuidv4() }]);
                            handleSend(session.id, user.user_id, input);
                        } else {
                            
                        }
                    }}
                >
                    <input
                        id="queryInput"
                        name="queryInput"
                        autoComplete="on"
                        value={input}
                        onKeyDown={handleKeyDown}
                        onChange={handleTextChange}
                        placeholder="Start Building models..."
                        className="w-full p-3 pr-12 border border-gray-300 rounded-full bg-white text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-transparent shadow-lg shadow-blue-200/50"
                    />
                    {showDropdown && ( 
                        <div className="absolute bg-white border border-gray-300 rounded-lg shadow-lg mb-1 w-64 bottom-full">
                            <ul  className="text-left">
                                <li className="p-2 hover:bg-gray-100 cursor-pointer flex items-center">Valuation Models
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ml-auto text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                                    </svg>
                                </li>
                                <li className="p-2 hover:bg-gray-100 cursor-pointer">Portfolio & Asset Pricing Models</li>
                                <li className="p-2 hover:bg-gray-100 cursor-pointer">Fixed Income Models</li>
                                <li className="p-2 hover:bg-gray-100 cursor-pointer">Financial Statement Models</li>
                                <li className="p-2 hover:bg-gray-100 cursor-pointer">Risk Models</li>
                                <li className="p-2 hover:bg-gray-100 cursor-pointer">Quantitative Models</li>
                            </ul>
                        </div>
                    )}
                     <button
                        type="button" 
                        className="absolute right-12 top-1/2 transform -translate-y-1/2 text-blue-500 hover:text-blue-600 focus:outline-none"
                    >
                        <PaperClipIcon className="h-6 w-6" />
                    </button>
                    <button
                        type="submit"
                        className="absolute right-3 top-1/2 transform -translate-y-1/2 text-blue-500 hover:text-blue-600 focus:outline-none"
                        onClick={() => {
                            if (!loading && input.trim() !== '' && user?.user_id) {
                                if (session) {
                                    setMessages((messages) => [...messages, { text: input, sender: "user", msg_id: uuidv4() }]);
                                    handleSend(session.id, user.user_id, input);
                                } else {
                                    ToastMessage.error("Session is not available.");
                                }
                            }
                        }}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                        </svg>
                    </button>
                </form>
            </div>
        </div>
    );
}

export default Chat;