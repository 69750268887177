import React, { useState, useContext, useEffect } from 'react';
import { appContext } from '../hooks/provider.tsx';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as SpenLogo } from './Spen.svg';
import { ReactComponent as MicrosoftLogo }  from "./icons8-microsoft.svg"
import { ReactComponent as FactsetLogo }  from "./FactSet_wordmark.svg"
import { IStatus } from "./types.tsx";
import { Tooltip } from 'antd';
import axios from "axios";
import { message } from "antd";

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false); 
  const { login } = useContext(appContext);
  const [error, setError] = useState<IStatus | null>({
    status: true,
    message: "All good",
  });
  const [isMobile, setIsMobile] = useState(false); // Added state for mobile detection
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const loginUrl = ' https://5bej6il62i.execute-api.us-east-1.amazonaws.com/login'
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Set to true if screen width is less than 768px
    };

    handleResize(); // Check on initial render
    window.addEventListener('resize', handleResize); // Add event listener

    return () => {
      window.removeEventListener('resize', handleResize); // Clean up event listener
    };
  }, []);

  const handleLogin = async () => {
    if (isMobile) return;
    setError(null);
    setLoading(true);
    try {
      const response = await axios.post(loginUrl, {email: email , password: password});
      const data = response.data;
      console.log("security status1", data.status )
      console.log("security status2", data.data )
      console.log("security status", data.message )

      if (data.status) {
        const using = data.data
        const transformedUserData = {
          user_id: using.id,  
          email: using.email,
          name: using.name,
          organization_id: using.organization_id,
          password: using.password, 
          is_admin: using.is_admin   
        };

        login(transformedUserData);
        message.success('User set successfully!'); 
        navigate('/ra'); // Navigate to RAView after login
      } else {
        setError({ status: false, message: data.message });
        message.error(data.message);
      }
    } catch (err) {
      setError({ status: false, message: err.message });
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex flex-col justify-between bg-gray-50">
      {isMobile ? ( // Display message if on mobile
        <div className="flex-grow flex items-center justify-center">
          <div className="bg-white p-8 rounded-lg shadow-md w-96 text-center">
            The app cannot be used in mobile display, please switch to desktop or laptop.
          </div>
        </div>
      ) : (
        <div className="flex-grow flex items-center justify-center">
          <div className="bg-white p-8 rounded-lg shadow-md w-96">
            <div className="flex items-center justify-center mb-6">
              <SpenLogo className="w-8 h-8 mr-2" />
              <div className="text-2xl font-bold text-center">Millannial Labs</div>
            </div>
            <Tooltip title="Not Available"> {/* Add Tooltip */}
              <button className="w-full mb-3 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 flex items-center justify-center">
                <MicrosoftLogo className="w-5 h-5 mr-2" /> {/* Use MicrosoftLogo component */}
                Login with Microsoft
              </button>
            </Tooltip>
            <Tooltip title="Not available"> {/* Add Tooltip */}
              <button className="w-full mb-6 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 flex items-center justify-center">
                <FactsetLogo className="w-5 h-5 mr-2" /> {/* Use FactsetLogo component */}
                Login with Factset
              </button>
            </Tooltip>
            <div className="relative mb-6">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-300"></div>
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="px-2 bg-white text-gray-500">OR</span>
              </div>
            </div>
            <input
              type="email"
              placeholder="Work Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full mb-3 px-3 py-2 border border-gray-300 rounded-md"
            />
            <input
              type={showPassword ? "text" : "password"} // Toggle input type
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full mb-3 px-3 py-2 border border-gray-300 rounded-md"
            />
            <button
              onClick={() => setShowPassword(!showPassword)} // Toggle showPassword state
              className="w-full mb-6 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
            >
              {showPassword ? "Hide Password" : "Show Password"} {/* Button text */}
            </button>
            <button 
              onClick={() => handleLogin()}
              className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-500 hover:bg-blue-600"
              disabled={loading}
            >
              Login
            </button>
            <div className="mt-4 text-center text-sm">
              <a href="#" className="text-blue-500 hover:underline">Lost Password?</a>
              {' • '}
              <a href="/signup" className="text-blue-500 hover:underline">Register</a>
              {' • '}
              <a href="#" className="text-blue-500 hover:underline">Single Sign-on</a>
            </div>
          </div>
        </div>
      )}
      <footer className="mt-8 text-center text-sm text-gray-500">
        <p>© Millennial Labs 2024</p>
        <div>
          <a href="/privacypolicy" className="hover:underline">Privacy Policy</a>
          {' • '}
          <a href="#" className="hover:underline">Terms of Service</a>
        </div>
      </footer>
    </div>
  );
};

export default Login;