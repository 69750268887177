import React from 'react';
import { appContext } from '../hooks/provider.tsx';
import { LogoutOutlined, BellOutlined, QuestionCircleOutlined, SettingOutlined, EllipsisOutlined } from '@ant-design/icons';

const Header = () => {
  

  return (
    <header className="bg-gray-100 shadow-sm py-2 px-4 flex justify-end items-center">
            <div className="flex items-center space-x-4">
        <BellOutlined className="text-gray-600 text-xl" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}} /> {/* Added missing props */}
        <QuestionCircleOutlined className="text-gray-600 text-xl" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}} /> {/* Added missing props */}
        <SettingOutlined className="text-gray-600 text-xl" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}} /> {/* Added missing props */}
        <button
          
          className="text-gray-600 hover:text-gray-800 focus:outline-none"
        >
           <EllipsisOutlined 
            className="text-xl" 
            onPointerEnterCapture={() => {}} // Added missing prop
            onPointerLeaveCapture={() => {}} // Added missing prop
          />
        </button>
      </div>
    </header>
  );
};

export default Header;