import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';
import axios from 'axios';
import { appContext } from '../hooks/provider.tsx';

import Password from 'antd/es/input/Password';

const SecurityCode = () => {
  const [code, setCode] = useState('');
  const { user, setUser } = useContext(appContext);
  const navigate = useNavigate();
  const Firstlogin = 'https://g0rlxrtsr4.execute-api.us-east-1.amazonaws.com/firstlogin'

  const handleSubmit = async () => {
    try {
        console.log("current user is 1:", user)
        const response = await axios.post(Firstlogin, { token: code });  // Send token in request body
        const data = response.data;
        console.log("security status1", data.status )
        console.log("security status2", data.data )
        console.log("security status", data.message )
        if (data.status) {
            const using = data.data
            const transformedUserData = {
              user_id: using.id,  
              email: using.email,
              name: using.name,
              organization_id: using.organization_id,
              password: using.password, 
              is_admin: using.is_admin   
            };

            setUser(transformedUserData);
            message.success('User set successfully!'); 
            console.log("current user is 2:", user) 
            navigate('/ra'); 
            console.log("current user is 3:", user)
        } else {
            message.error(data.message);

        }
    } catch (err) {
        message.error(err.message);
        console.log('errormassage', err.message)
    }
};
useEffect(() => {
  console.log("Current user is:", user);
}, [user]);

  return (
    <div className="min-h-screen flex flex-col justify-between bg-gray-50">
      <div className="flex-grow flex items-center justify-center">
        <div className="bg-white p-8 rounded-lg shadow-md w-96">
          <div className="text-2xl font-bold text-center mb-6">Enter your security code</div>
          <p className="text-center mb-6">We've sent a 6-digit code to your email. Please enter it below. Be sure not to share this info with anyone!</p>
          <input
            type="text"
            placeholder="Security Code"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            className="w-full mb-6 px-3 py-2 border border-gray-300 rounded-md"
          />
          <button 
            onClick={handleSubmit}
            className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-500 hover:bg-blue-600"
          >
            Submit
          </button>
          <div className="mt-4 text-center text-sm">
            <a href="#" className="text-blue-500 hover:underline">Resend me the code</a>
          </div>
        </div>
      </div>
      <footer className="mt-8 text-center text-sm text-gray-500">
        <p>© Millennial Labs 2024</p>
        <div>
          <a href="#" className="hover:underline">Privacy Policy</a>
          {' • '}
          <a href="#" className="hover:underline">Terms of Service</a>
        </div>
      </footer>
    </div>
  );
};

export default SecurityCode;