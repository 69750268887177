import React, { useState, useContext } from 'react';
import {
    TableCellsIcon,
    UserIcon,
    DocumentChartBarIcon,
    PencilSquareIcon,
    PencilIcon,
    PlusIcon,
    Square3Stack3DIcon,
    HomeIcon,
} from "@heroicons/react/24/outline";
import axios from "axios";
import { useConfigStore } from "../../../hooks/store.tsx";
import { Dropdown, MenuProps, message } from "antd";
import { appContext } from "../../../hooks/provider.tsx"; // Corrected import path
import { IStatus, IChatSession, IChatMessage } from "../../types.tsx";
import { useNavigate } from 'react-router-dom';

import './Sideview.css'

const Sidebar = ({ className , loading, setLoading , onReportBuilderClick, startOnboarding }: { className?: string , loading: boolean, setLoading: React.Dispatch<React.SetStateAction<boolean>> , onReportBuilderClick: () => void, startOnboarding: () => void; }) => {
    
    const navigate = useNavigate(); // Initialize useNavigate
    
    
    const [error, setError] = React.useState<IStatus | null>({
        status: true,
        message: "All good",
    });
    const session = useConfigStore((state) => state.session);
    const sessions = useConfigStore((state) => state.sessions);
    const setSessions = useConfigStore((state) => state.setSessions);
    const messages = useConfigStore((state) => state.messages);
    const setMessages = useConfigStore((state) => state.setMessages);
    const setSession = useConfigStore((state) => state.setSession);
    const [selectedSession, setSelectedSession] = React.useState<null>();
    const sessionMessagesUrl = 'https://42r1mw2g3b.execute-api.us-east-1.amazonaws.com/session/messages';
    const createSessionUrl = 'https://m8lzdrkijh.execute-api.us-east-1.amazonaws.com/session';
    const listSessionUrl = 'https://fdqsopvdk4.execute-api.us-east-1.amazonaws.com/sessions';
    const { user, setUser } = useContext(appContext);

    const createSession = async () => {
        setError(null);
        setLoading(true);
        try {
          const response = await axios.post(createSessionUrl, {user_id:user?.user_id});
          const data = response.data;
          if (data && data.result) {
            message.success("Session created successfully");
            setSessions((prevSessions) => [...prevSessions, data.result.session_id]);
           
          } else {
            message.error("Failed to create session");
          }
        } catch (err) {
          setError(err);
          message.error(err.message);
        } finally {
          setLoading(false);
        }
      };
    
    

    const fetchSession = async (sessionId: string) => {
        setError(null);
        setLoading(true);
        try {
            const response = await axios.get(sessionMessagesUrl, {
                params: { session_id: sessionId ,  user_id:user?.user_id },
            });
            const data = response.data;
            console.log(data);
            if (data && data.status && Array.isArray(data.data)) { // Check if data.data is an array
            const transformedMessages = data.data
            .filter((msg: any) => msg.send_to !== "CodeInterpreter" && msg.send_from !== "CodeInterpreter") // Skip messages with send_to or send_from == "CodeInterpreter"
                .map((msg: any) => ({
                    text: msg.message,
                    sender: msg.send_from === "User" ? "user" : "bot",
                    msg_id: msg.id,
                }));
            setSession({ id: sessionId });
            console.log("trans:", transformedMessages);
            setMessages(transformedMessages);
            console.log("Session:", session);
            console.log("Messages:", data.data);
        }  else {
                message.error("Unexpected response format or status is false");
            }
        } catch (err) {
            setError(err);
            message.error(err.message);
        } finally {
            setLoading(false);
        }
        
    };

    const fetchSessions = async () => {
        setError(null);
        setLoading(true);
        try {
          const response = await axios.get(`${listSessionUrl}?user_id=${user?.user_id}`); // Pass user_id as query parameter
          const data = response.data;
          if (data && data.status) {
            console.log("Fetched sessions:", data.data); // Log fetched sessions
            setSessions(data.data); // Update sessions state with fetched session IDs
          } else {
            message.error(data.message);
          }
        } catch (err) {
          setError(err);
          message.error(err.message);
        } finally {
          setLoading(false);
        }
      };
    
      
      
    
      React.useEffect(() => {
        fetchSessions(); // Fetch sessions when the component mounts
      }, []);

    const sessionRows = sessions.map((sessionId: string, index: number) => {
        const isSelected = session?.id === sessionId;
        const rowClass = isSelected ? "bg-accent text-white" : "bg-secondary text-primary";
        const loadingClass = loading ? "glowing" : "";

        let items: MenuProps["items"] = [
            {
                label: (
                    <div
                        onClick={() => {
                            setSelectedSession({ id: sessionId, name: sessionId, user_id: user?.email || "" });
                        }}
                    >
                        <PencilIcon
                            role={"button"}
                            title={"Edit"}
                            className="h-4 w-4 mr-1 inline-block"
                        />
                        Edit
                    </div>
                ),
                key: "edit",
            },
        ];

        const menu = (
            <Dropdown
                menu={{ items }}
                trigger={["click"]}
                placement="bottomRight"
            >
                <div
                    role="button"
                    className={`float-right ml-2 duration-100 hover:bg-secondary font-semibold px-2 pb-1 rounded ${isSelected ? "hover:text-accent" : ""}`}
                >
                    <span className={`block -mt-2 ${isSelected ? "text-white" : ""}`}>
                        ...
                    </span>
                </div>
            </Dropdown>
        );

        return (
            <div
                key={"sessionsrow" + index}
                className={`group relative mb-2 pb-1 border-b border-dashed ${loadingClass}`} // Apply glowing class
            >
                {items.length > 0 && (
                    <div className="absolute right-2 top-2 group-hover:opacity-100 opacity-0">
                        {menu}
                    </div>
                )}
                <div
                    className={`rounded p-2 cursor-pointer ${rowClass}`}
                    role="button"
                    onClick={() => {
                        fetchSession(sessionId);
                        console.log("Fetching session1:", sessionId);
                    }}
                >
                    <div className="text-xs mt-1">
                        <Square3Stack3DIcon className="h-4 w-4 inline-block mr-1" />
                        {sessionId} {/* Display session ID */}
                    </div>
                    <div className="text-xs text-right">
                    </div>
                </div>
            </div>
        );
    });

    return (
        <div className="h-full w-1/4 p-4 bg-gray-800 border-r border-gray-700 flex flex-col justify-between">
            <div>
                <div className="flex items-center justify-between mb-4">
                    <div className="text-white font-semibold">Start a new session</div>
                    <div className="flex items-center">
                    <HomeIcon 
                            onClick={() => { setSession(null); navigate('/ra'); }} // Navigate to RAView which includes Dashboard
                            className="h-6 w-6 text-white cursor-pointer mr-2" 
                        />
                        <PlusIcon 
                            onClick={() => {
                                createSession();
                            }}
                            className="h-6 w-6 text-white cursor-pointer" 
                        />
                    </div>
                </div>
                <div className="mb-4">
                    <input
                        type="text"
                        placeholder="Search sessions..."
                        className="w-full p-2 rounded bg-gray-700 text-white"
                    />
                </div>
                <div className="overflow-y-auto max-h-64"> {/* Added max-height and overflow-y for scrollable session rows */}
                    {sessionRows}
                </div>
            </div>
            <div className="flex flex-col gap-4">
                <div className="flex items-center gap-2 p-2 bg-gray-700 rounded-md cursor-pointer">
                    <TableCellsIcon className="h-6 w-6 text-white" />
                    <span className="text-white">Financial Models</span>
                </div>
                <div className="flex items-center gap-2 p-2 bg-gray-700 rounded-md cursor-pointer">
                    <UserIcon className="h-6 w-6 text-white" />
                    <span className="text-white">Market Workers</span>
                </div>
                <div className="flex items-center gap-2 p-2 bg-gray-700 rounded-md cursor-pointer" onClick={onReportBuilderClick} >
                    <DocumentChartBarIcon className="h-6 w-6 text-white" />
                    <span className="text-white"> Analyst Reports</span>
                </div>
                <div className="flex items-center gap-2 p-2 bg-gray-700 rounded-md cursor-pointer">
                    <PencilSquareIcon className="h-6 w-6 text-white" />
                    <span className="text-white">Account</span>
                </div>
            </div>
        </div>
    );
}

export default Sidebar;